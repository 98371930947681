import React from 'react';

const imgCDN = 'https://cdn.vn.garenanow.com/web/fo3/fo4/fo4-reset-pass-2/';

const Image = ({
  src = '',
  imgClass = '',
}) => {
  return (
    <img className={imgClass ? imgClass : ''} src={imgCDN + src} alt=""/>
  );
};

export default Image;
