import request from 'lib/request';
import { showError, showSuccess } from '../lib/commons';

export const CURRENT_USER_REQUEST_ERROR   = 'CURRENT_USER_REQUEST_ERROR';
export const CURRENT_USER_REQUEST_SUCCESS = 'CURRENT_USER_REQUEST_SUCCESS';
export const CURRENT_USER_FETCHING        = 'CURRENT_USER_FETCHING';

export const CONFIGURATION_ERROR   = 'CONFIGURATION_ERROR';
export const CONFIGURATION_SUCCESS = 'CONFIGURATION_SUCCESS';
export const CONFIGURATION_FETCHING        = 'CONFIGURATION_FETCHING';

export const SEND_OTP_ERROR   = 'SEND_OTP_ERROR'
export const SEND_OTP_SUCCESS = 'SEND_OTP_SUCCESS'
export const IS_SENDING_OTP   = 'IS_SENDING_OTP'

export const RESET_ERROR   = 'RESET_ERROR'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const IS_RESETTING   = 'IS_RESETTING'

export const RESET_BY_OTP_ERROR   = 'RESET_BY_OTP_ERROR'
export const RESET_BY_OTP_SUCCESS = 'RESET_BY_OTP_SUCCESS'
export const IS_RESETTING_OTP   = 'IS_RESETTING_OTP'

// USER
export const isCurrentUserFetching = () => {
  return {
    type: CURRENT_USER_FETCHING
  }
}

export const getCurrentUser = (callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isCurrentUserFetching());
    request('api/user/get').then(function (response) {
      if (response.status == 'successful') {
        dispatch(getCurrentUserSuccess(response));
        callback();

        if (!response.payload.user.account_id) {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      } else {
        dispatch(getCurrentUserError(response))
        if (response.error_code == 'AccountNotFoundError') {
          swal({
            title: 'Thông báo',
            html: '<p class="pop-content">Bạn chưa tạo HLV, vui lòng tạo HLV rồi quay lại.</p>',
            animation: false,
            confirmButtonText: 'Đóng',
            customClass: 'custom-modal animated zoomIn'
          }).then((result) => {
            window.location.href = "/user/logout"
          })
        }
      }
    })
  }
}

export const getCurrentUserSuccess = (response) => {
  return {
    type: CURRENT_USER_REQUEST_SUCCESS,
    payload: response.payload
  }
}

export const getCurrentUserError = (response) => {
  return {
    type: CURRENT_USER_REQUEST_ERROR,
    payload: response
  }
}

// SEND OTP
export const isSendingOtp = () => {
  return {
    type: IS_SENDING_OTP
  }
}

export const sendOtp = (params, method, type, callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isSendingOtp());
    request('api/user/send_otp_v2','POST', {
      // body: JSON.stringify({ params } )
      body: params
    }).then(function(response) {
      if (response.status == 'successful') {
        dispatch(sendOtpSuccess(response));
        const { currentUser: { configuration } } = getState();

        if(method == 'METHOD_OTP_VIA_PHONE') {
          showSuccess('send_otp_success', { phone: configuration.phone_method?.phone_number, minutes: response?.payload?.otp_life_time/60 });
        }
        if(method == 'METHOD_OTP_VIA_EMAIL') {
          showSuccess('send_otp_mail_success', { mail: configuration.email_method?.email, minutes: response?.payload?.otp_life_time/60 });
        }
        callback(response?.payload);
      } else {
        showError(response.error_code, 'Lỗi', {
          type: type
        });
        dispatch(sendOtpError(response));
      }
    })
  }
}

export const sendOtpSuccess = (response) => {
  return {
    type: SEND_OTP_SUCCESS,
    payload: response
  }
}

export const sendOtpError = (response) => {
  return {
    type: SEND_OTP_ERROR,
    payload: response
  }
}

// RESET PASSWORD BY ANSWER
export const isResetting = () => {
  return {
    type: IS_RESETTING
  }
}

export const resetByAnswer = (answer, resetLevel, callback) => {
  return (dispatch, getState) => {
    dispatch(isResetting());
    request('api/user/reset_pass_2_by_secret_question', 'POST', {
      body: JSON.stringify({ answer })
    }).then(function(response) {
      if (response.status == 'successful') {
        dispatch(resetByAnswerSuccess(response));
        showSuccess('reset_success', { level: resetLevel }, () => {
          window.location.reload();
        });
        callback();
      } else {
        showError(response.error_code, 'Lỗi', {
          message: response.message
        });
        dispatch(resetByAnswerError(response));
      }
    })
  }
}

export const resetByAnswerSuccess = (response) => {
  return {
    type: RESET_SUCCESS,
    payload: response
  }
}

export const resetByAnswerError = (response) => {
  return {
    type: RESET_ERROR,
    payload: response
  }
}

// RESET PASSWORD BY OTP
export const isResettingOtp = () => {
  return {
    type: IS_RESETTING_OTP
  }
}

export const resetByOtp = (params, resetLevel, callback) => {
  return (dispatch, getState) => {
    dispatch(isResettingOtp());
    request('api/user/reset_password_v2', 'POST', {
      body: params
    }).then(function(response) {
      if (response.status == 'successful') {
        dispatch(resetByOtpSuccess(response));
        showSuccess('reset_success', { level: resetLevel }, () => {
          window.location.reload();
        });
        callback();
      } else {
        dispatch(resetByOtpError(response));
        showError(response.error_code, 'Lỗi', {
            time: response?.time
        });
      }
    })
  }
}

export const resetByOtpSuccess = (response) => {
  return {
    type: RESET_BY_OTP_SUCCESS,
    payload: response
  }
}

export const resetByOtpError = (response) => {
  return {
    type: RESET_BY_OTP_ERROR,
    payload: response
  }
}


//GET USER CONFIGURATION
export const isConfigurationFetching = () => {
  return {
    type: CONFIGURATION_FETCHING
  }
}

export const getConfiguration = (method, type, callback = () => {}) => {
  return (dispatch, getState) => {
    dispatch(isConfigurationFetching());
    request(`api/user/get_configuration?method=${method}&password_type=${type}`).then(function (response) {
      if (response.status == 'successful') {
        dispatch(getConfigurationSuccess(response));
        callback();
      } else {
        dispatch(getConfigurationError(response))
      }
    })
  }
}

export const getConfigurationSuccess = (response) => {
  return {
    type: CONFIGURATION_SUCCESS,
    payload: response.payload
  }
}

export const getConfigurationError = (response) => {
  return {
    type: CONFIGURATION_ERROR,
    payload: response
  }
}
