import React, { useEffect, Suspense } from 'react';
import { Router, Route } from 'react-router-dom';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import { Switch } from 'react-router';
import { createBrowserHistory as createHistory } from 'history';
import { updateLocation } from 'store/location';
import MainLayout from 'layouts/PageLayout/PageLayout'
import Spinner from '../components/Spinner'

const history = createHistory();
const Home = React.lazy(() => import('routes/Home'))

const App = ({store}) => {
  useEffect(() => {
    history.listen(updateLocation(store));
  }, []);

  return (
    <Provider store={store}>
      <Router
        history={history}
        onUpdate={() => {
          window.scrollTo(0, 0)
        }}>
        <Suspense fallback={<Spinner />}>
          <MainLayout>
            <Switch>
              <Route exact path="/" component={Home} />
            </Switch>
          </MainLayout>
        </Suspense>
      </Router>
    </Provider>
  );
};

export default App;
