import {
  CURRENT_USER_REQUEST_ERROR,
  CURRENT_USER_REQUEST_SUCCESS,
  CURRENT_USER_FETCHING,

  CONFIGURATION_ERROR,
  CONFIGURATION_SUCCESS,
  CONFIGURATION_FETCHING,

  SEND_OTP_ERROR,
  SEND_OTP_SUCCESS,
  IS_SENDING_OTP,

  RESET_ERROR,
  RESET_SUCCESS,
  IS_RESETTING,

  RESET_BY_OTP_ERROR,
  RESET_BY_OTP_SUCCESS,
  IS_RESETTING_OTP,
} from './actions';

const initialState = {
  loading: false,
  isSendingOtp: false,
  isResettingByOtp: false,
  isResettingByAnswer: false,
  isConfigurationing: false,
  login: false,
  user: {},
  userStatusInfos: {},
  otpLifeTime: null,
  configuration: {},
  errorCodeConfiguration: '',
}

export default function currentUserReducer(state = initialState,  { type, payload } = action) {

  switch (type) {
    // USER
    case CURRENT_USER_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        login: true,
        user: payload.user,
        otpLifeTime: payload.otp_life_time,
        userStatusInfos: payload.user_status_infos,
      };
    case CURRENT_USER_REQUEST_ERROR:
      return {
        ...state,
        loading: false,
      };
    case CURRENT_USER_FETCHING:
      return {
        ...state,
        loading: true
      };

    // SEND OTP
    case SEND_OTP_SUCCESS:
      return {
        ...state,
        isSendingOtp: false
      };

    case SEND_OTP_ERROR:
      return {
        ...state,
        isSendingOtp: false
      };

    case IS_SENDING_OTP:
      return {
        ...state,
        isSendingOtp: true
      };

    // RESET PASSWORD BY ANSWER
    case RESET_SUCCESS:
      return {
        ...state,
        isResettingByAnswer: false
      };

    case RESET_ERROR:
      return {
        ...state,
        isResettingByAnswer: false
      };

    case IS_RESETTING:
      return {
        ...state,
        isResettingByAnswer: true
      };

    // RESET PASSWORD BY ANSWER
    case RESET_BY_OTP_SUCCESS:
      return {
        ...state,
        isResettingByOtp: false
      };

    case RESET_BY_OTP_ERROR:
      return {
        ...state,
        isResettingByOtp: false
      };

    case IS_RESETTING_OTP:
      return {
        ...state,
        isResettingByOtp: true
      };

    // GET CONFIGURATION
    case CONFIGURATION_SUCCESS:
      return {
        ...state,
        configuration: payload ? payload : {},
        errorCodeConfiguration: '',
        isConfigurationing: false
      };

    case CONFIGURATION_ERROR:
      return {
        ...state,
        configuration: {},
        errorCodeConfiguration: payload?.error_code,
        isConfigurationing: false
      };

    case CONFIGURATION_FETCHING:
      return {
        ...state,
        isConfigurationing: true
      };

    // DEFAULT
    default:
      return state;
  }
}
