import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { getCurrentUser } from 'authentication/actions';
import Header from '../Header';
import Footer from '../Footer';

const PageLayout = ({currentUser, location, children, check, getCurrentUser}) => {
  useEffect(() => {
    getCurrentUser();
  }, []);

  return (
    <div id="wrapper">
      <Header/>
      <main id="main">
        {children}
      </main>
      <Footer/>
    </div>
  );
};

const mapDispatchToProps = {
  getCurrentUser
};

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PageLayout));
