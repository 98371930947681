import Swal from 'sweetalert2';

export function showErrorWithCode(errCode, data = {}) {
  let errorMess
  switch (errCode) {
    case 'EmailNotVerifiedError':
      errorMess = 'Bạn chưa đăng nhập'
      break;
    case 'not_logged_in':
      errorMess = 'Bạn chưa đăng nhập'
      break;
    case 'missing_account_id':
      errorMess = 'Tài khoản này chưa có HLV, vui lòng tạo HLV và quay lại sau'
      break;
    case 'server_error':
      errorMess = 'Xảy ra lỗi, hãy thử lại sau!'
      break;
    case 'api_error':
      errorMess = 'Thao tác không thực hiện được, xin vui lòng thử lại sau'
      break;
    case 'update_info_required':
      errorMess = 'Vui lòng cập nhật thông tin'
      break;
    case 'error_zero_spin':
      errorMess = 'Bạn đã hết lượt sút'
      break;
    case 'invalid_action':
      errorMess = 'Thao tác không thực hiện được'
      break;
    case 'event_not_open_yet':
      errorMess = 'Sự kiện chưa bắt đầu'
      break;
    case 'already_shared':
      errorMess = 'Bạn đã share thành công và đóng góp cổ vũ ngày hôm nay.'
      break;
    case 'not_enough_shoot_balance':
      errorMess = 'Bạn đã hết lượt nã pháo'
      break;
    case 'error_reward_not_found':
      errorMess = 'Phần quà không tồn tại'
      break;
    case 'match_ended':
      errorMess = 'Trận đấu đã kết thúc.'
      break;
    case 'time_is_up':
      errorMess = 'Đã hết thời gian dự đoán trận đấu này.'
      break;
    case 'error_not_enough_point':
      errorMess = 'Bạn không đủ điểm để đổi vật phẩm.'
      break;
    case 'error_limit_exchange':
      errorMess = 'Bạn đã đổi vật phẩm này. Hãy chọn vật phẩm khác nhé.'
      break;
    case 'error_not_enough_fc':
      errorMess = 'Bạn không đủ FC, vui lòng nạp thêm FC và thử lại.'
      break;
    case 'error_not_enough_money':
      errorMess = 'Bạn không có đủ FC hoặc MC để chơi.'
      break;
    case 'error_have_exchanged':
      errorMess = 'Bạn đã đổi quà này rồi.'
      break;
    case 'error_time_invalid':
      errorMess = 'Thời gian sự kiện đã kết thúc, cảm ơn bạn đã quan tâm.'
      break;
    case 'error_open_id_invalid':
      errorMess = 'Bị lỗi, bạn vui lòng đăng nhập lại và thử lại.'
      break;
    case 'error_open_id_none':
      errorMess = 'Bị lỗi, bạn vui lòng đăng nhập lại và thử lại.'
      break;
    case 'error_open_id_none':
      errorMess = 'Bị lỗi, bạn vui lòng đăng nhập lại và thử lại.'
      break;
    case 'error_account_not_found':
      errorMess = 'Không tìm thấy tài khoản.'
      break;
    case 'error_not_have_phone_number':
      errorMess = 'Tài khoản của bạn chưa xác nhận số điện thoại, <br/>vui lòng xác nhận tại <a href="https://account.garena.com/security/mobile/apply" target="_blank"><strong>đây</strong></a>'
      break;
    case 'WrongOtpError':
      errorMess = `Mã OTP không chính xác. Bạn đã nhập sai ${data?.time} lần. Vui lòng kiểm tra và nhập lại.`;
      break;
    case 'HavingActiveOtpError':
      errorMess = `Mã OTP đã được gửi đi.`
      break;
    case 'error_already_locked':
      errorMess = 'Tài khoản này đã được khóa. Xin vui lòng kiểm tra lại.'
      break;
    case 'error_otp_expire':
      errorMess = 'Mã OTP đã hết hạn.'
      break;
    case 'error_otp_invalid':
      errorMess = 'Mã OTP không hợp lệ.'
      break;
    case 'ReachedMaxOtpPerDayError':
      errorMess = 'Bạn đã nhập sai mã OTP 5 lần khiến cho mã OTP này không còn hiệu lực, <br/>vui lòng lấy mã OTP mới sau 3 phút và thử lại.'
      break;
    case 'ReachedMaxNumberOfChangedPasswordPerDay':
      errorMess = `Bạn đã khởi tạo mật khẩu ${data?.type == 'PASSWORD_TYPE_2' ? 'cấp 2' : 'cấp 3'} quá nhiều lần, hãy thử lại sau 24 giờ. Nếu cần khoá khẩn cấp game, bạn hãy thực hiện tại web sau: <a href="https://khoatk.fo4.garena.vn/" target="_blank">https://khoatk.fo4.garena.vn/</a>`
      break;
    case 'ReachedMaxNumberOfChangedPasswordPerMonth':
      errorMess = `Bạn đã khởi tạo mật khẩu ${data?.type == 'PASSWORD_TYPE_2' ? 'cấp 2' : 'cấp 3'} quá nhiều lần, hãy thử lại sau 30 ngày. Nếu cần khoá khẩn cấp game, bạn hãy thực hiện tại web sau: <a href="https://khoatk.fo4.garena.vn/" target="_blank">https://khoatk.fo4.garena.vn/</a>`
      break;
    case 'error_many_request_wait_time':
      errorMess = 'Vui lòng lấy mã OTP mới sau 3 phút.'
      break;
    case 'error_user_define_message':
      errorMess = data.message
      break;
    case 'error_user_define_message':
      errorMess = data.message
      break;
    case 'TemporaryLockResetPass':
      errorMess = 'BẠN ĐÃ NHẬP SAI MÃ OTP 4 LẦN NÊN MÃ OTP NÀY KHÔNG CÒN HIỆU LỰC, VUI LÒNG LẤY MÃ OTP MỚI SAU 24 GIỜ VÀ THỬ LẠI.';
      break;
    case 'WrongAnswerError':
      errorMess = 'Câu trả lời không chính xác.';
      break;
    case 'CannotSendMobileMessageError':
      errorMess = 'Không gửi được OTP đến điện thoại của ban.';
      break;
    case 'NoActiveOtpOrExpiredOtpError':
      errorMess = 'Mã OTP đã hết hạn.';
      break;
    case 'error_surpass_limit_num_opt':
      errorMess = 'Bạn đã yêu cầu gửi mã OTP quá nhiều lần, vui lòng thử lại sau 24 giờ. Trường hợp cần khoá khẩn cấp bảo vệ tài khoản game FO4, bạn vui lòng thực hiện tại web sau: <a href="https://khoatk.fo4.garena.vn/">https://khoatk.fo4.garena.vn/</a>';
      break;
    case 'error_surpass_limit_num_reset_pass':
      errorMess = 'Bạn đã khởi tạo mật khẩu 2 quá nhiều lần, hãy thử lại sau 24 giờ. <br/> Nếu cần khoá khẩn cấp game FO4, bạn hãy thực hiện tại web sau: <a href="https://khoatk.fo4.garena.vn">https://khoatk.fo4.garena.vn</a>';
      break;
    case 'error_phone_number_invalid':
      errorMess = 'Số điện thoại không đúng'
      break;
    case 'PhoneNotSatisfiedError':
      errorMess = 'Số điện thoại không đúng'
      break;
    default:
      errorMess = 'Có lỗi xảy ra. Vui lòng thử lại sau';
      break;
  }
  return errorMess
}

export function showError(code, title = 'Lỗi', data = {}, onClose = () => {}) {
  const msg = showErrorWithCode(code, data);

  return Swal.fire({
    title: title,
    html: `<p class="pop-content">${msg}</p>`,
    confirmButtonText: 'Đóng',
    customClass: 'custom-modal',
    showCloseButton: false,
    allowOutsideClick: true,
    showClass: {
      popup: 'animated zoomIn'
    },
    hideClass: {
      popup: 'animated zoomOut'
    },
    onClose: () => {
      onClose();
      if (code === 'error_many_request') {
        window.location.reload();
      }
    }
  })
}

export function showSuccess(code, data = {}, onClose = () => {}, title = 'Thông báo') {
  let msg = '';

  switch (code) {
    case 'send_otp_success':
      msg = `Gửi mã thành công về SĐT ${data.phone}! <br/>Mã OTP chỉ có hiệu lực trong ${data.minutes} phút`;
      break;

    case 'send_otp_mail_success':
      msg = `Gửi mã thành công về Email ${data.mail}! <br/>Mã OTP chỉ có hiệu lực trong ${data.minutes} phút`;
      break;

    case 'reset_success':
      msg = `Khôi phục mật khẩu ${data.level} thành công. <br/>Hãy truy cập game FC Online khởi tạo mật khẩu ${data.level} mới.`;
      break;

    default:
      msg = '';
      break;
  }

  return Swal.fire({
    title: title,
    html: `<p class="pop-content">${msg}</p>`,
    confirmButtonText: 'Đóng',
    customClass: 'custom-modal',
    showCloseButton: false,
    allowOutsideClick: true,
    showClass: {
      popup: 'animated zoomIn'
    },
    hideClass: {
      popup: 'animated zoomOut'
    },
    onClose: () => {
      onClose();
    }
  })
}

export function showConfirm(msg = '', btnCancel = null, title = 'Thông báo', btnConfirm = 'Xác nhận') {
  return Swal.fire({
    title: `<span class="popup-alert__title">${title}</span>`,
    html: `
      <div class="popup-alert__content">
        <h5 class="popup-alert__message">${msg}</h5>
      </div>
    `,
    confirmButtonText: btnConfirm,
    showCancelButton: btnCancel ? true : false,
    cancelButtonText: btnCancel,
    showCloseButton: true,
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    showClass: {
      popup: 'animate__animated animate__fadeInDown animate__faster'
    },
    hideClass: {
      popup: 'animate__animated animate__fadeOutUp animate__faster'
    },
    customClass: {
      popup: 'popup-alert popup-confirm'
    },
  })
}

export function toObj(array, key) {
  var result = array.reduce(function(map, obj) {
    map[obj[key]] = obj;
    return map;
  }, {});

  return result;
}

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  return true;
}
