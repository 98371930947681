import React from 'react';
import Image from '../components/Image';

const Header = () => {
  return (
    <header id="header" className="header">
      <div className="header__logo">
        <img src="http://cdn-fco.garenanow.com/games/fcovn/logo.png"/>
      </div>
    </header>
  );
};

export default Header;
